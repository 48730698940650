import 'simplelightbox/dist/simple-lightbox.min.css';
import SimpleLightbox from 'simplelightbox';


document.addEventListener("DOMContentLoaded", function () {
  new SimpleLightbox('#gallery a', {
    captionsData: 'alt',
    captionDelay: 100,
    showCounter: false
  });
})