import { activeClassName } from './variables'

const getPath = () => {
  let path = window.location.pathname.split('/')[1];
  if (path.includes('.html')) {
    path = path.split('.')[0];
  }
  return path;
};

const setActiveLink = () => {
  const path = getPath();
  const active = document.getElementById(path);
  const allLink = document.querySelectorAll('.header__link');
  allLink.forEach((link) => {
    link.classList.remove(activeClassName);
  });
  if (active) {
    active.classList.add(activeClassName);
  } else {
    document.getElementById('home').classList.add(activeClassName);
  }
};

export { setActiveLink };
