import { setActiveLink } from './js/utils';
import { className } from './js/variables';
import './sass/main.scss';
import './js/slider';
import './js/gallery';

const burger = document.querySelector('.menu-icon');
const headerList = document.querySelector('.header__list');
const small = window.matchMedia('(max-width: 1024px)')

document.addEventListener('DOMContentLoaded', function () {
  if(!small.matches) {
    document.addEventListener('scroll', () => {
      if (window.scrollY > 13) {
        document.querySelector('.header').classList.add(className.headerBg);
      } else {
        document.querySelector('.header').classList.remove(className.headerBg);
      }
    });
  }

  setActiveLink();

  if(small.matches) {
    burger.addEventListener('click', () => {
      burger.classList.toggle('menu-active');
      if(burger.classList.contains('menu-active') && small.matches) {
        headerList.style.display = 'flex'
        document.addEventListener('click', handleClickOutside, true);
      } else {
        headerList.style.display = 'none'
        document.removeEventListener('click', handleClickOutside, true);
      }
    })
  }

  function handleClickOutside(event) {
    const isClickInside = headerList.contains(event.target) || burger.contains(event.target);
    if (!isClickInside && burger.classList.contains(className.menuActive)) {
      burger.classList.remove(className.menuActive);
      headerList.style.display = 'none';
      document.removeEventListener('click', handleClickOutside, true);
    }
  }
});
